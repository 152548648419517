import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import { isValidEmail } from "../../../utils/validation";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { TAction, User } from "../type";
import style from "./users.module.css"
import { useErrorHandling } from "../../../context/errorHandlingContext";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { getActiveUser, USER_API_URL } from "../vision.constant";

export const Users = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [editingUser, setEditingUser] = useState<User | null>(null);
    const [deleteUserId, setDeleteUserId] = useState<any>(null);
    const [user, setUser] = useState<User>({ name: '', email: '' });
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState("");
    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);
    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);
    const columns: Column[] = [
        { id: 'id', label: 'Id' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'action', label: 'Action', align: 'center' },
    ];
    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                console.log('Yes:', { deleteUserId, editingUser, user });
                if (deleteUserId) {
                    deleteUser()
                } else if (editingUser || user) {
                    saveUser()
                }
            }, variant: 'contained', color: 'success'
        },
    ]
    useEffect(() => {
        fetchUsers();
        return () => {
            updateBreadcrumbList(true, "")
        }
    }, []);
    const fetchUsers = async () => {
        try {
            setLoader(true)
            const data: any = await api.get<any>(`${USER_API_URL}`);
            setUsers(data.response);
        } catch (error) {
            showToast("Error fetching user list", "error");
            console.error('Error fetching users:', error);
        } finally {
            setLoader(false);
        }
    };
    const updateBreadcrumbList = (rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    };
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser(prev => ({
            ...prev,
            [name]: value
        }));
        if (name === 'name') {
            setNameError('');
        } else if (name === 'email') {
            setEmailError('');
        }
    };
    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true)
        setModalTitle(`Are you wanted to ${editingUser ? 'update' : 'add'} this user?`)
    };
    const validateInputs = () => {
        let isValid = true;
        if (!user.name.trim() || !user.name) {
            setNameError('Name is required');
            isValid = false;
        }
        if (!user.email.trim() || !user.email) {
            setEmailError('Email is required');
            isValid = false;
        } else if (!isValidEmail(user.email)) {
            setEmailError('Enter a valid email');
            isValid = false;
        }
        return isValid;
    };
    const saveUser = async () => {
        try {
            setLoader(true);
            if (editingUser) {
                const payload = {
                    ...user,
                    updated_by: getActiveUser()
                }
                await api.put<User>(`${USER_API_URL}/${editingUser?.id}`, payload);
            } else {
                const payload = {
                    ...user,
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                }
                await api.post<User>(`${USER_API_URL}`, payload);
            }
            setEditingUser(null);
            setDeleteUserId(null)
            await fetchUsers();
            updateBreadcrumbList(true, "")
            setSearchQuery("");
            clearInputs();
            showToast(`User ${editingUser ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`User ${editingUser ? 'updating' : 'adding'} failed`, 'error');
            console.error('Error adding user:', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };
    const patchValue = (user: User) => {
        setDeleteUserId(null)
        setEditingUser(user);
        setUser(user);
        scrollToTop();
        setShowAddIcon(false);
        updateBreadcrumbList(false, "Edit User")
    };
    const handleDelete = (user: User) => {
        console.log('delete user', user);
        setShowModal(true)
        setDeleteUserId(user?.id);
        setModalTitle("Are you wanted to delete this user?")
    }
    const deleteUser = async () => {
        try {
            setLoader(true);
            await api.remove<User>(`${USER_API_URL}/${deleteUserId}`);
            fetchUsers();
            clearInputs();
            showToast("User deleted successfully", "success");
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast("Error deleting user", "error");
            console.error('Error deleting user:', error);
        } finally {
            setLoader(false);
            setEditingUser(null);
        }
    };
    const clearInputs = () => {
        setUser({ name: '', email: '' });
        setNameError('');
        setEmailError('');
    };
    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditingUser(null);
        updateBreadcrumbList(true, "")
        setSearchQuery("");
    };
    const handleAdd = () => {
        setEditingUser(null);
        updateBreadcrumbList(false, "Add User")
        setShowAddIcon(false);
        setDeleteUserId(null)
        setSearchQuery("");
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        console.log({ event, newPage }, 'handleChangePage');
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        console.log({ event }, 'handleChangeRowsPerPage');
    };
    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    };
    const handleClose = () => {
        setShowModal(false);
    };
    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };
    const filteredUserList = useMemo(() => {
        return filterByTableSearch(users, searchQuery);
    }, [users, searchQuery]);
    return (
        <>
            <div className={style.user__container}>
                <strong>Users</strong>
                <div className={style.user__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editingUser ? 'Update' : "Save"}>
                                <img width={24} height={20} className={`${style.user__curserPointer} ${style.user__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                            { }
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.user__inputWrapper}>
                    <Grid item xs={4} >
                        <Input
                            id="username-input"
                            heading="User Name"
                            placeholder="Enter User Name"
                            variant="standard"
                            name="name"
                            value={user.name}
                            helperText={nameError}
                            required
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Input
                            id="email-input"
                            name="email"
                            heading="User Email"
                            required
                            placeholder="Enter User Email"
                            variant="standard"
                            helperText={emailError}
                            value={user.email}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredUserList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredUserList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
        </>
    );
};
export default Users; 
