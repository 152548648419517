import axios, { AxiosResponse, AxiosError, AxiosHeaders } from "axios";

const SERVER_URL: string = "";
const validStatus = [200, 201, 202, 204];
const BASE_HEADERS: Record<string, string> = {};

interface ErrorResponse {
    status: number;
    statusText: string;
}

interface RequestOptions {
    headers?: Record<string, string>;
    withCredentials?: boolean;
    params?: Record<string, any>;
}

export class ApiHelper {
    private showToastMessage: (message: string, severity: "error" | "warning" | "info" | "success") => void;
    private handleLogout: () => void;

    constructor(showToastMessage: (message: string, severity: "error" | "warning" | "info" | "success") => void, handleLogout: () => void) {
        this.showToastMessage = showToastMessage;
        this.handleLogout = handleLogout;
        this.handleError = this.handleError.bind(this);
    }

    get<T>(uri: string, params?: Record<string, any>): Promise<T> {
        return axios
            .get(SERVER_URL + uri, {
                headers: this.getHeaders(),
                withCredentials: false,
                params
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }
    delete<T>(uri: string, data: any): Promise<T> {
        return axios
            .delete<T>(SERVER_URL + uri, {
                data,
                headers: this.getHeaders(),
                withCredentials: false
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    post<T>(uri: string, data: any): Promise<T> {
        return axios
            .post(SERVER_URL + uri, data, {
                headers: this.getHeaders(),
                withCredentials: false
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    put<T>(uri: string, data: any): Promise<T> {
        return axios
            .put(SERVER_URL + uri, data, {
                headers: this.getHeaders(),
                withCredentials: false
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    remove<T>(uri: string): Promise<T> {
        return axios
            .delete(SERVER_URL + uri, {
                headers: this.getHeaders(),
                withCredentials: false
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    upload<T>(uri: string, data: any): Promise<T> {
        const formData = new FormData();
        formData.append("file", data.file);

        return axios
            .post(SERVER_URL + uri, formData, {
                headers: {
                    ...this.getHeaders(),
                    "Content-Type": "multipart/form-data"
                },
                responseType: "blob",
                withCredentials: false
            })
            .then(this.checkResponse)
            .catch(this.handleError);
    }

    private getHeaders(multipart = false): Record<string, string> {
        let defaultHeaders = { ...BASE_HEADERS };

        if (multipart) {
            defaultHeaders = {};
        }

        const token = sessionStorage.getItem("accessToken");
        if (token) {
            defaultHeaders.Authorization = `Bearer ${token}`;
        } else {
            defaultHeaders.Authorization = "";
        }

        return defaultHeaders;
    }

    private checkResponse<T>(response: AxiosResponse<T>): T {
        if (validStatus.includes(response.status)) {
            return response.data;
        }

        if (response.status === 401) {
            localStorage.clear();
            this.showToastMessage("Session expired. Please log in again.", "error");
            this.handleLogout();
            throw new Error("USER_ANONYMOUS");
        } else {
            throw new Error(response.statusText);
        }
    }

    private handleError(error: AxiosError<ErrorResponse>): Promise<never> {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.clear();
                    sessionStorage.clear();
                    this.showToastMessage("Unauthorized. Please log in again.", "error");
                    this.handleLogout();
                    return Promise.reject(new Error("UN_AUTHORIZED"));
                case 400:
                    // sessionStorage.clear();
                    // this.showToastMessage("Bad request. Please try again.", 'warning');
                    // this.showToastMessage("Session expired. Please log in again.", 'error');
                    // this.handleLogout();
                    return Promise.reject(new Error("SESSION_EXPIRED"));
                case 403:
                case 404:
                case 409:
                    return Promise.reject(error.response.data);
                default:
                    return Promise.reject(error.response.data);
            }
        } else {
            return Promise.reject(error.message);
        }
    }
}

export default ApiHelper;
