// export const BACKEND_URI = "http://localhost:50505";
export const BACKEND_URI = "https://lntcs.lntecc.com";

export const RESOURCE_NAME = "https://ltc-coe-genai01-appservice.azurewebsites.net";
export const STORAGE_ACCOUNT = "ltccoegenai01sa";
export const SAS_TOKEN =
    "?sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2024-12-31T18:23:49Z&st=2024-02-22T10:23:49Z&spr=https&sig=Eu0WveahmPzvsBFw9T4raNUWI3aR4dodWL3LJVl%2BgIk%3D";
//export const VISION_AI_URL = "https://coe-computervision.azure-api.net/dev/test";
//export const WC_VISION_AI_URL = "coe-computervision.azure-api.net/dev/test";
export const WC_VISION_AI_URL = "13.71.46.251:5200";
export const VISION_AI_URL = "http://4.247.177.50:5200";
//----------------------
// export const IMG_URL = " http://20.207.196.169:8080";
export const IMG_URL = "https://coe-computervision.azure-api.net/dev";
export let RUN_MODE = "deploy";
export const HR_IFRAME_URL = "https://hraichurn.azurewebsites.net/";
export const SCM_IFRAME_URL = "https://scmaicp.azurewebsites.net/";
export const FINANCE_IFRAME_URL = "https://sentimentshapp.azurewebsites.net/";

export const ADMIN_EMAIL_LIST: string[] = [
    "kokila.vanarajan@lntecc.com",

    "dasari.nandini@lntecc.com",

    "sivask@lntecc.com",

    "shanmugasundaram.s1@lntecc.com",

    "sanjay-m@lntecc.com",

    "k-rahman@lntecc.com",

    "navin.d-cnt@lntecc.com",

    "anbarasan.m-cnt@lntecc.com",

    "akhila.mylarapu-cnt@lntecc.com"
];
//export let RUN_MODE = "local";

//-------old application------------------

// export const BACKEND_URI = "https://lntcs.lntecc.com";
// export const RESOURCE_NAME = "https://ltc-coe-genai-appservice.azurewebsites.net";
// export const STORAGE_ACCOUNT = "ltccoegenaisa";
// export const SAS_TOKEN ="?sv=2022-11-02&ss=b&srt=sco&sp=rwdlaciytfx&se=2024-12-31T12:43:51Z&st=2023-12-30T04:43:51Z&spr=https&sig=9gxM2ohwCqWE5QRcGJDQR0aHbVEEBK8Os%2FhwCPaCOJQ%3D";
