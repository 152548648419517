import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import { Column } from "../../../design system/Table/type";
import Input from "../../../design system/Input";
import ApiHelper from "../../../api/ApiHelper";
import SaveIcon from "../../../assets/Table/save.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid, Tooltip, IconButton } from "@mui/material";
import { scrollToTop } from "../../../utils/scroll";
import CustomTableComponent from "../../../design system/Table";
import CustomDialog from "../../../design system/Dialog/Index";
import { AdminContext } from "../../../context/admin.context";
import { filterByTableSearch } from "../../../utils/tableFilter";
import { TAction, User } from "../type";
import style from "./developer.module.css";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useErrorHandling } from "../../../context/errorHandlingContext";
import { ALGORITHM_API_URL, getActiveUser } from "../vision.constant";


export const Developer = () => {
    const [algos, setAlgos] = useState<any[]>([]);
    const [editingAlgo, setEditingAlgo] = useState<any | null>(null);
    const [deleteId, setDeleteId] = useState<any>(null);
    const [algo, setAlgo] = useState<any>({ algorithm_id: null, algorithm_name: '', algorithm_params: [] });
    const [showAddIcon, setShowAddIcon] = useState<boolean>(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [nameError, setNameError] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState("");
    const {
        breadCrumb,
        setBreadcrumbs,
        setLoader,
        setOpenToast,
        setToastMessage,
        setToastSeverity,
        showModal,
        setShowModal,
        modalTitle,
        setModalTitle
    } = useContext(AdminContext);

    const { showToastMessage, handleLogout } = useErrorHandling();
    const api = new ApiHelper(showToastMessage, handleLogout);

    const columns: Column[] = [
        { id: 'algorithm_id', label: 'Id' },
        { id: 'algorithm_name', label: 'Name' },
        // { id: 'algorithm_params', label: 'Params' },
        { id: 'action', label: 'Action', align: 'center' },
    ];

    const actions: TAction[] = [
        {
            label: 'No', onClick: () => {
                setShowAddIcon(true);
                clearInputs();
            }, variant: 'contained', color: 'error'
        },
        {
            label: 'Yes', onClick: () => {
                console.log('Yes:', { deleteId, editingAlgo, algo });
                if (deleteId) {
                    deleteAlgo();
                } else if (editingAlgo || algo) {
                    saveAlgo();
                }
            }, variant: 'contained', color: 'success'
        },
    ];

    useEffect(() => {
        fetchAlgo();
        return () => {
            updateBreadcrumbList(true, "");
        };
    }, []);

    const fetchAlgo = async () => {
        try {
            setLoader(true);
            const data: any = await api.get<any[]>(ALGORITHM_API_URL);
            setAlgos(data.response);
        } catch (error) {
            showToast("Error fetching algorithm list", "error");
            console.error('Error fetching algorithm:', error);
        } finally {
            setLoader(false);
        }
    };

    const updateBreadcrumbList = (rm: boolean, type: string) => {
        setBreadcrumbs((prevBreadcrumbs) => {
            if (rm) {
                return prevBreadcrumbs.slice(0, 3);
            }
            if (!type) return prevBreadcrumbs;
            return [...prevBreadcrumbs.slice(0, 3), { label: type, link: "no-route" }];
        });
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        console.log({ name, event }, 'handleInputChange*******');

        if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
            if (index === -1) { // This is for the name input
                setAlgo((prev: any) => ({
                    ...prev,
                    algorithm_name: value
                }));
                if (name === 'algorithm_name') {
                    setNameError("");
                }
            } else { // This is for the params input
                setAlgo((prev: any) => {
                    const updatedParams = [...prev.algorithm_params];
                    updatedParams[index] = { ...updatedParams[index], [name]: value };
                    return { ...prev, algorithm_params: updatedParams };
                });
            }
        }
    };


    const handleAddParam = () => {
        setAlgo((prev: any) => ({
            ...prev,
            algorithm_params: [...prev.algorithm_params, { label: '', value: '' }]
        }));
    };

    const handleRemoveParam = (index: number) => {
        setAlgo((prev: any) => ({
            ...prev,
            algorithm_params: prev.algorithm_params.filter((_: any, i: number) => i !== index)
        }));
    };

    const handleSubmit = async () => {
        if (!validateInputs()) {
            return;
        }
        setShowModal(true);
        setModalTitle(`Are you sure you want to ${editingAlgo ? 'update' : 'add'} this algorithm?`);
    };

    const validateInputs = () => {
        let isValid = true;
        if (!algo?.algorithm_name.trim() || !algo?.algorithm_name) {
            setNameError('Name is required');
            isValid = false;
        }
        if (algo.algorithm_params.length === 0 || algo.algorithm_params.some((p: any) => !p.label.trim() || !p.value.trim())) {
            showToast('parameter labels and values are required', 'error');
            isValid = false;
        }
        return isValid;
    };


    const saveAlgo = async () => {
        const transformedData = {
            algorithm_name: algo.algorithm_name,
            algorithm_params: algo.algorithm_params.reduce((acc: any, param: any) => {
                acc[param.label] = param.value;
                return acc;
            }, {})
        };
        console.log(transformedData, "transformedData");
        try {
            setLoader(true);
            if (editingAlgo) {
                const payload = {
                    algorithm_id: editingAlgo.algorithm_id,
                    algorithm_name: transformedData.algorithm_name,
                    algorithm_params: JSON.stringify(transformedData.algorithm_params),
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                };
                await api.put<any>(`${ALGORITHM_API_URL}/${editingAlgo?.algorithm_id}`, payload);
            } else {
                const payload = {
                    algorithm_name: transformedData.algorithm_name,
                    algorithm_params: JSON.stringify(transformedData.algorithm_params),
                    created_by: getActiveUser(),
                    updated_by: getActiveUser(),
                };
                await api.post<any>(ALGORITHM_API_URL, payload);
            }
            await fetchAlgo();
            setEditingAlgo(null);
            setDeleteId(null)
            updateBreadcrumbList(true, "");
            setSearchQuery("");
            clearInputs();
            showToast(`Algorithm ${editingAlgo ? 'updated' : 'added'} successfully`, 'success');
        } catch (error) {
            showToast(`Algorithm ${editingAlgo ? 'updating' : 'adding'} failed`, 'error');
            console.error('Error adding :', error);
        } finally {
            setLoader(false);
            setShowAddIcon(true);
        }
    };

    const patchValue = (algo: any) => {
        console.log(algo, 'algo********patchValue*******');
        const transformedData = {
            ...algo,
            algorithm_params: JSON.parse(algo.algorithm_params)
        }
        console.log(transformedData, 'transformedData');

        const originalData = {
            ...transformedData,
            algorithm_name: transformedData.algorithm_name,
            algorithm_params: Object.keys(transformedData.algorithm_params).map((key) => ({
                label: key,
                value: transformedData.algorithm_params[key]
            }))
        };

        setDeleteId(null);
        setEditingAlgo(originalData);
        setAlgo(originalData);
        scrollToTop();
        setShowAddIcon(false);
        updateBreadcrumbList(false, "Edit Algorithm");
    };

    const handleDelete = (algo: any) => {
        console.log(algo, 'algo*******handleDelete********');
        setShowModal(true);
        setDeleteId(algo?.algorithm_id);
        setModalTitle("Are you sure you want to delete this algorithm?");
    };

    const deleteAlgo = async () => {
        try {
            setLoader(true);
            await api.remove<any>(`${ALGORITHM_API_URL}/${deleteId}`);
            fetchAlgo();
            clearInputs();
            showToast("Algorithm deleted successfully", "success");
            scrollToTop();
            setSearchQuery("");
        } catch (error) {
            showToast("Error deleting algorithm", "error");
            console.error('Error deleting algorithm:', error);
        } finally {
            setLoader(false);
        }
    };

    const clearInputs = () => {
        setAlgo({ algorithm_name: '', algorithm_params: [] });
        setNameError('');
    };

    const handleRemove = () => {
        setShowAddIcon(true);
        clearInputs();
        setEditingAlgo(null);
        updateBreadcrumbList(true, "");
        setSearchQuery("");
    };

    const handleAdd = () => {
        setEditingAlgo(null);
        updateBreadcrumbList(false, "Add Algorithm");
        setShowAddIcon(false);
        setDeleteId(null);
        setSearchQuery("");
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const showToast = (message: string, severity: 'error' | 'warning' | 'info' | 'success') => {
        setToastMessage(message);
        setToastSeverity(severity);
        setOpenToast(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPage(0);
    };

    const filteredAlgoList = useMemo(() => {
        return filterByTableSearch(algos, searchQuery);
    }, [algos, searchQuery]);

    return (
        <>
            <div className={style.algo__container}>
                <strong>Algorithm</strong>
                <div className={style.algo__iconContainer}>
                    {!showAddIcon &&
                        <>
                            <Tooltip title={editingAlgo ? 'Update' : "Save"}>
                                <img width={24} height={20} className={`${style.algo__curserPointer} ${style.algo__saveIcon}`} src={SaveIcon} alt="saveIcon" onClick={handleSubmit} />
                            </Tooltip>
                            <Tooltip title="Close">
                                <HighlightOffIcon onClick={handleRemove} className={style.circle__x} />
                            </Tooltip>
                        </>
                    }
                    {showAddIcon && (
                        <Tooltip title="Add">
                            <AddCircleOutlineIcon onClick={handleAdd} className={style.circle__plus} />
                        </Tooltip>
                    )}
                </div>
            </div>
            {!showAddIcon && (
                <Grid container spacing={2} className={style.algo__inputWrapper}>
                    <Grid item xs={4}>
                        <Input
                            id="name-input"
                            heading="Name"
                            placeholder="Enter Name"
                            variant="standard"
                            name="algorithm_name"
                            required
                            helperText={nameError}
                            value={algo.algorithm_name}
                            onChange={(e) => handleInputChange(e, -1)}
                        />
                    </Grid>
                    <Grid item sx={{ display: "flex", alignItems: "end" }}>
                        <IconButton onClick={handleAddParam}>
                            <Tooltip title="Add Params">
                                <AddCircleOutlineIcon className={style.algo__curserPointer} />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                    {algo?.algorithm_params?.map((param: any, index: number) => (
                        <Grid container spacing={2} key={index} className={style.algo__inputWrapper} style={{ padding: "16px 0 0 16px" }}>
                            <Grid item xs={3}>
                                <Input
                                    id={`label-input-${index}`}
                                    name="label"
                                    heading="Label"
                                    placeholder="Enter Label"
                                    variant="standard"
                                    value={param.label}
                                    onChange={(e) => handleInputChange(e, index)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Input
                                    id={`value-input-${index}`}
                                    name="value"
                                    heading="Value"
                                    placeholder="Enter Value"
                                    variant="standard"
                                    value={param.value}
                                    onChange={(e) => handleInputChange(e, index)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => handleRemoveParam(index)}>
                                    <RemoveCircleOutlineIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            )}
            <CustomTableComponent
                rows={filteredAlgoList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                columns={columns}
                totalCount={filteredAlgoList.length}
                handleEdit={patchValue}
                handleRemove={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50]}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                isEdit={true}
            />
            <CustomDialog
                open={showModal}
                onClose={handleClose}
                title={modalTitle}
                actions={actions}
            />
        </>
    );
};

export default Developer;
